import React from 'react'

const Footer = () => {
    return (
        <div>
 <footer className="container">
  <p className="float-right"><a href="#">Back to top</a></p>
  <p>© DUCHUY Company, Inc. · <a href="#">Privacy</a> · <a href="#">Terms</a></p>
</footer>

   
        </div>
    )
}

export default Footer
